import React from 'react';
import Link from 'next/link';

import { Box } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Routes from '@/router/routes';
import { SECTION_HEADER_TEXT, SECTION_HEADER_LINK_TEXT } from '@/homepage/HutFavoritesSection/constants/hutFavorite';

import useHutFavoriteHeaderClasses from './styles';

const HutFavoritesHeader = () => {
  const classes = useHutFavoriteHeaderClasses();

  return (
    <Box display="flex" alignItems="baseline" mb={2} data-testid="homepage-pop_pizza_header-text">
      <h1 className={classes.header}>{SECTION_HEADER_TEXT}</h1>

      <Box display="flex">
        <Link href={Routes.MENU.PIZZA} legacyBehavior>
          <a href="replace" className={classes.link}>{SECTION_HEADER_LINK_TEXT}</a>
        </Link>
        <ArrowForwardIosIcon fontSize="small" className={classes.caret} />
      </Box>
    </Box>
  );
};

export default HutFavoritesHeader;
