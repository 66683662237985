import React, { useEffect, useState } from 'react';
import { Button, Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorOutline } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { isNil } from 'remeda';
import colors from '../../common/colors';
import CloseIcon from '../../localization/icons/CloseIcon';
import { mobileStartBreakpoint } from '../../materialUi/theme';
import fontStyles from '../../common/fontStyles';
import boxShadows from '../../common/boxShadows';
import { onGqlError } from '../../graphql/errors/actions';
import { QueryNames } from '../../graphql/errors/constants';
import AnchorLink from '../../common/AnchorLink';
import useScrollTrackingForAnalytics from '../../dataAnalytics/hooks/useScrollTrackingForAnalytics';
import { createPromo, onInitialHomepagePageLoadPromoInView } from '../../dataAnalytics/dataAnalyticsHelper';
import { BREAKING_NEWS } from '../../graphql/queries';
import { useVersionQuery } from '../../graphql/hooks/useVersionQuery';
import { updatePromoData } from '../../ReduxApplication/actions';
import { PromoType } from '../../dataAnalytics/dataAnalytics.d';
import { BreakingNews } from '../../graphql/types/BreakingNews';
import { AnalyticsDataModel } from '@/rootStateTypes';

const useStyles = makeStyles((theme: Theme) => createStyles({
  greyBackground: {
    backgroundColor: colors.gray200
  },
  centeredContainerWrapper: {
    maxWidth: '1152px',
    margin: '0 auto'
  },
  centeredContainer: {
    minHeight: '40px',
    paddingLeft: '27px',
    paddingRight: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'top',
    alignItems: 'top',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      paddingLeft: '17px',
      paddingRight: '16px'
    }
  },
  closeIcon: {
    height: '24px',
    width: '24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      height: '18px',
      width: '18px'
    }
  },
  infoIcon: {
    height: '16px',
    width: '16px',
    marginTop: '12px'
  },
  closeButton: {
    display: 'flex',
    alignItems: 'flex-start',
    height: '24px',
    width: '24px',
    margin: '8px 0 0 0',
    padding: 0,
    borderRadius: 0,
    minWidth: '24px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      marginTop: '11px'
    },
    '&:focus': {
      boxShadow: boxShadows.redUnderline
    }
  },
  banner: {
    ...fontStyles.body,
    flexGrow: 1,
    margin: '8px 0 8px 8px',
    fontSize: '18px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '14px',
      margin: '10px 0 10px 8px'
    }
  },
  link: {
    ...fontStyles.textLink,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    },
    '&:focus': {
      textDecoration: 'underline'
    },
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      fontSize: '14px'
    }
  }
}));

const BreakingNewsBanner = (): JSX.Element | null => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [closed, setClosed] = useState(false);
  const {
    data, loading, error, storeID
  } = useVersionQuery<BreakingNews>({
    queryFn: BREAKING_NEWS,
    storeSpecific: false
  });

  const breakingNewsData = data?.breakingnews;
  const promo = (datamodel: AnalyticsDataModel) => createPromo(datamodel?.page?.promo?.breakingNews);
  const label = 'Breaking News';

  useEffect(() => {
    if (breakingNewsData) {
      dispatch(updatePromoData(PromoType.BREAKING_NEWS_PROMO, breakingNewsData));
    }
  }, [breakingNewsData, dispatch]);

  const ref = useScrollTrackingForAnalytics(
    onInitialHomepagePageLoadPromoInView,
    label,
    promo,
    0.75,
    loading
  );

  const finishedLoadingButNoData = !loading && !data?.breakingnews;

  useEffect(() => {
    if (error || finishedLoadingButNoData) {
      // @ts-expect-error - existing code
      dispatch(onGqlError(QueryNames.BREAKING_NEWS, error, storeID));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, finishedLoadingButNoData]);

  if (loading || error || finishedLoadingButNoData) {
    return null;
  }

  const closeBanner = () => setClosed(true);
  // @ts-expect-error - existing code
  const { link, text, active } = data.breakingnews;
  const inactive = active === false || isNil(active);
  if (closed || inactive) return null;

  return (
    <Grid data-testid="homepage-break_news_banner-box" className={classes.greyBackground}>
      <Grid className={classes.centeredContainerWrapper}>
        <Grid className={classes.centeredContainer}>
          <ErrorOutline data-testid="homepage-break_news_banner-info" className={classes.infoIcon} />
          <span ref={ref} data-testid="homepage-break_news_banner-text" className={classes.banner}>
            {text}&nbsp;
            <AnchorLink
              testId="homepage-break_news_banner-text_link"
              className={classes.link}
              dataAnalyticsCategory="breaking_news_banner"
              dataAnalyticsAction={link.linkDisplayText}
              {...link}
            />
          </span>
          <Button disableRipple classes={{ root: classes.closeButton }} onClick={closeBanner}>
            <CloseIcon data-testid="homepage-break_news_banner-close" className={classes.closeIcon} aria-label="Close banner" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BreakingNewsBanner;
