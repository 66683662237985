import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { getDealNameFromUrl } from '../url';
import { NATIONAL } from '../../localization/constants';
import AnchorLink from '../AnchorLink';
import { RootState } from '@/rootStateTypes';
import { useSalesExperience } from '@/salesExperience/hooks';
import { selectors } from '@/salesExperience/salesExperience.slice';
import { localizationSelectors } from '@/localization/localizationSelectors';

interface DealLinkProps extends LinkContent {
  linkRef?: any;
  tabIndex?: number;
  testId: string;
  className?: string;
  children?: JSX.Element;
  dataAnalyticsCategory?: string;
  dataAnalyticsAction?: string;
}

const DealLink = ({
  link,
  linkTitle,
  linkType,
  linkDisplayText,
  external,
  className,
  children,
  testId,
  tabIndex,
  dataAnalyticsCategory,
  dataAnalyticsAction,
  linkRef
}: DealLinkProps): JSX.Element => {
  const [clickDelayed, setClickDelayed] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const isLocalized = useSelector(
    (state: RootState) => state.domain.localization.localizedStore !== NATIONAL
  );
  const { linkForSalesExperience } = useSalesExperience();
  const experience = useSelector(selectors.experience);
  const localizationToken = useSelector(
    localizationSelectors.localizationToken
  );

  const linkContent = children || linkDisplayText;
  const isRelativeLink = linkType === 'relativeLink';
  const dealName = getDealNameFromUrl(link);
  const shouldTreatAsDealLink = Boolean(dealName);
  const linkTypeOnAnchorLink = shouldTreatAsDealLink ? '' : linkType;
  const processDealOrFollowLink = useCallback(() => {
    if (shouldTreatAsDealLink && link !== undefined && dealName && experience) {
      router.push(linkForSalesExperience(link) || link);
    }
  }, [dealName, dispatch, experience, isRelativeLink, link, linkForSalesExperience, shouldTreatAsDealLink]);

  const beforeProcessDeal = useCallback((forceContinue = false) => {
    if (!localizationToken) {
      setClickDelayed(true);
      return;
    }

    const continueWithAction = forceContinue || !clickDelayed;
    if (continueWithAction && isLocalized) {
      processDealOrFollowLink();
    }
  }, [localizationToken, clickDelayed, isLocalized, processDealOrFollowLink]);

  useEffect(() => {
    if (clickDelayed && localizationToken) {
      setClickDelayed(false);
      beforeProcessDeal(true);
    }
  }, [localizationToken, clickDelayed, beforeProcessDeal]);

  return (
    <AnchorLink
      preventHref={shouldTreatAsDealLink}
      link={link}
      linkType={linkTypeOnAnchorLink}
      linkTitle={linkTitle}
      linkDisplayText={linkDisplayText}
      external={external}
      className={className}
      testId={testId}
      tabIndex={tabIndex}
      dataAnalyticsCategory={dataAnalyticsCategory}
      dataAnalyticsAction={dataAnalyticsAction}
      linkRef={linkRef}
      onClick={beforeProcessDeal}
      handleAfterLocalizing={processDealOrFollowLink}
    >
      {linkContent as JSX.Element}
    </AnchorLink>
  );
};

export default DealLink;
