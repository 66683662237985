import React, { useEffect } from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Card, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { MARKETING_BANNERS } from '../../graphql/queries';
import AnchorLink from '../../common/AnchorLink';
import ResponsiveImageRender from '../../common/ResponsiveImageRender';
import fontStyles from '../../common/fontStyles';
import { QueryNames } from '../../graphql/errors/constants';
import { onGqlError } from '../../graphql/errors/actions';
import { logMissingImageError, logMissingLinkError, logArrayEmptyError } from '../../common/logger/logGraphqlError';
import useScrollTrackingForAnalytics from '../../dataAnalytics/hooks/useScrollTrackingForAnalytics';
import { createPromo, onInitialHomepagePageLoadPromoInView, onPromoClick } from '../../dataAnalytics/dataAnalyticsHelper';
import { breakPointsValues, largeStartBreakpoint, mobileStartBreakpoint } from '../../materialUi/theme';
import { getPaddingTopForAspectRatio } from '../../common/aspectRatio';
import telemetry from '../../telemetry';
import { useVersionQuery } from '../../graphql/hooks/useVersionQuery';
import { updatePromoData } from '../../ReduxApplication/actions';
import { PromoType } from '../../dataAnalytics/dataAnalytics.d';
import useWindowSize from '../../common/useWindowSize';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { AnalyticsDataModel } from '@/rootStateTypes';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    marginTop: '36px',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      marginTop: '20px'
    }
  },
  anchorLink: {
    ...fontStyles.commonComponentSelectedState,
    display: 'block'
  },
  image: {
    display: 'block',
    width: '100%',
    weight: '100%',
    position: 'absolute',
    top: '0',
    left: '0'
  },
  imageCard: {
    paddingTop: getPaddingTopForAspectRatio(541, 282),
    position: 'relative'
  }
}));

const getMarketingBannersData = (data: MarketingBanner | undefined) => (data?.marketingBanners || [])
  .filter(Boolean)
  // @ts-expect-error - existing code
  .sort((a, b) => a.index - b.index)
  .slice(0, 2);

interface SingleBannerProps {
  index: number;
  banner: MarketingBannerContent;
  classes: Record<string, string>;
}

function useMarketingBannerRef(index: number) {
  const label = `Marketing Banner ${index + 1}`;
  const promo = (dataModel: AnalyticsDataModel) => createPromo(dataModel?.page?.promo?.marketingBanners
    && dataModel?.page?.promo?.marketingBanners[index]);
  const ref = useScrollTrackingForAnalytics(onInitialHomepagePageLoadPromoInView, label, promo);
  const analytics = useAnalytics();
  const marketingBanners = analytics.analyticsDataModel.page?.promo?.marketingBanners;

  const passPromoOnClickAnalytics = () => {
    if (!marketingBanners) return;
    telemetry.addCustomEvent(`homepage-marketing-banner-${index + 1}-click`);
    analytics.push(() => onPromoClick(label, promo(analytics.analyticsDataModel)));
  };

  return { passPromoOnClickAnalytics, ref };
}

const SingleBanner = ({ index, banner, classes }: SingleBannerProps) => {
  const { passPromoOnClickAnalytics, ref } = useMarketingBannerRef(index);

  return (
    <Grid item xs={12} md={6} onClick={passPromoOnClickAnalytics}>
      <AnchorLink
        {...banner.link}
        tabIndex={0}
        className={classes.anchorLink}
        testId={`homepage-mkt_banner_${index + 1}-link`}
        dataAnalyticsCategory="marketing_banner"
        dataAnalyticsAction={`Marketingbanner_${index}`}
        linkRef={ref}
      >
        <Card className={classes.imageCard}>
          <ResponsiveImageRender
            {...banner.image}
            className={classes.image}
            testId={`homepage-mkt_banner_${index + 1}-image`}
          />
        </Card>
      </AnchorLink>
    </Grid>
  );
};

const MarketingBanners = (): JSX.Element | null => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width && windowSize.width < breakPointsValues[largeStartBreakpoint];
  const dispatch = useDispatch();
  const {
    data, loading, error, storeID
  } = useVersionQuery<MarketingBanner>({
    queryFn: MARKETING_BANNERS,
    storeSpecific: false
  });
  const marketingBannersData = data?.marketingBanners;
  const finishedLoadingButNoData = !loading && !marketingBannersData;
  const banners = getMarketingBannersData(data);

  useEffect(() => {
    if (data) {
      logMissingImageError(banners, 'Marketing Banner', storeID);
      logMissingLinkError(banners, 'Marketing Banner', storeID);
    }
  }, [banners, data, storeID]);

  useEffect(() => {
    if (marketingBannersData) {
      // @ts-expect-error - existing code
      dispatch(updatePromoData(PromoType.MARKETING_BANNERS_PROMO, marketingBannersData, isMobile));
    }
  }, [dispatch, isMobile, marketingBannersData]);

  useEffect(() => {
    if (error || finishedLoadingButNoData) {
      // @ts-expect-error - existing code
      dispatch(onGqlError(QueryNames.MARKETING, error, storeID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, finishedLoadingButNoData]);

  if (loading || error || finishedLoadingButNoData) {
    return null;
  }

  if (banners.length === 0) {
    logArrayEmptyError('Marketing Banner', storeID);
    return <div data-testid="no-mkt-banners" />;
  }

  return (
    <Grid container spacing={3} className={classes.root} data-testid="homepage-mkt-banners">
      {
        banners.map((banner: MarketingBannerContent, index: number) => (
          <SingleBanner
            index={index}
            banner={banner}
            classes={classes}
            key={`marketing-banner-${index}`}
          />
        ))
      }
    </Grid>
  );
};

export default MarketingBanners;
