import React from 'react';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import { Skeleton , Chip } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useStyles from './styles';

interface SidekickContentProps {
  title?: string;
  subTitle: string;
  badgeText?: string;
  loading: boolean;
}

const SidekickContent = (
  {
    title = '', subTitle = '', badgeText, loading = false
  }: SidekickContentProps
) => {
  const classes = useStyles();
  const cardTitle = loading ? <Skeleton data-testid="sidekick-skeleton-title" /> : <span aria-hidden>{title}</span>;
  const cardSubTitle = loading ? <Skeleton data-testid="sidekick-skeleton-subtitle" width="60%" /> : <span aria-hidden>{subTitle}</span>;
  const cardBadge = loading
    ? <Skeleton data-testid="sidekick-skeleton-subtitle" width="30%" />
    : <Chip size="small" variant="outlined" color="primary" label={badgeText} className={classes.badgeText} />;

  return (
    <CardHeader
      classes={{
        title: classes.title,
        action: classes.actionButton
      }}
      action={(
        <IconButton tabIndex={-1} className={classes.icon} disableRipple aria-label={`${title}: ${subTitle}. ${badgeText || ""}. Click to order.`}>
          <ArrowForwardIosIcon fontSize="small" className={classes.arrowIcon} />
        </IconButton>
      )}
      title={cardTitle}
      subheader={(
        <>
          <p aria-hidden className={classes.subTitle}>{cardSubTitle}</p>
          {badgeText && cardBadge}
        </>
      )}
    />
  );
};

export default SidekickContent;
