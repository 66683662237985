import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../common/colors';
import fontStyles from '../../common/fontStyles';
import { mobileStartBreakpoint } from '@/materialUi/theme';

type LinkableMenuStyles = {
  outOfStock: boolean | undefined;
  hasLinkEnabled: boolean;
};

const styles = makeStyles((theme) => createStyles({
  card: {
    width: '728px',
    borderBottom: `1px solid ${colors.gray400}`,
    borderRadius: '0px',
    boxShadow: 'none',
    overflow: 'visible'
  },
  cardActionArea: {
    cursor: ({ hasLinkEnabled }: LinkableMenuStyles) => (hasLinkEnabled ? 'pointer' : 'default'),
    width: '100%',
    height: '163px',
    padding: '10px 0',
    pointerEvents: ({ hasLinkEnabled }: LinkableMenuStyles) => (hasLinkEnabled ? 'auto' : 'none'),
    color: ({ hasLinkEnabled }: LinkableMenuStyles) => (!hasLinkEnabled ? 'transparent' : 'false'),
    '&:focus-visible': {
      color: 'unset'
    },
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      height: 'auto',
      paddingBottom: '15px'
    }
  },
  title: {
    ...fontStyles.productTitle,
    margin: '0 0 1px',
    lineHeight: '1.5',
    paddingTop: 3,
    fontWeight: 600,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.productTitleMobile
    }
  },
  dealMenuItemTitle: {
    paddingRight: '20px'
  },
  outOfStock: {
    color: colors.gray601
  },
  price: {
    fontFamily: 'open_sans',
    fontWeight: 'normal',
    fontSize: '14px'
  },
  description: {
    fontFamily: 'open_sans',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '1.5',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      '& .break': {
        wordBreak: 'break-all'
      }
    }
  },
  outOfStockTitle: {
    '& > .badgeText': {
      display: 'inline-block'
    }
  },
  popularTitle: {
    marginTop: '3px'
  },
  image: {
    margin: '0 10px 1px 0',
    padding: '5px 3px 0 0',
    objectFit: 'cover',
    minWidth: '86px',
    width: '86px',
    height: '100px',
    borderRadius: '10px',
    opacity: ({ outOfStock }: LinkableMenuStyles) => (outOfStock ? 0.5 : 1),
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      marginRight: '1em',
      margin: '2px 12px 8px 0',
      minWidth: '218px',
      width: '218px',
      height: '113px'
    }
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '4px'
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '4px'
  },
  flexEnd: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingRight: '4px'
  },
  caratIcon: {
    height: '15px',
    alignSelf: 'flex-end',
    color: colors.gray900,
    margin: '0 9px'
  },
  content: {
    paddingLeft: '0',
    paddingRight: '0.5em',
    '&:last-child': {
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  },
  vegetarianIcon: {
    color: '#008900'
  },
  textContent: {
    flex: '2'
  },
  regularLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      postion: 'absolute'
    }
  },
  dealMenuIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      postion: 'absolute'
    },
    '&>span': {
      padding: '0'
    }
  },
  dealMenuItemContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  paddingRightZero: {
    paddingRight: 0
  },
  contentGrid: {
    '&.MuiGrid-container': {
      boxSizing: 'border-box',
      marginRight: '-12px',
      flexWrap: 'unset'
    }
  },
  allergenDisclaimer: {
    ...fontStyles.infoText
  },
  newIcon: {
    marginTop: '8px'
  },
  ctaButton: {
    marginRight: '16px'
  }
}));

export default styles;
