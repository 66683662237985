import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { getPaddingTopForAspectRatio } from '@/common/aspectRatio';
import { largeStartBreakpoint, mobileStartBreakpoint } from '@/materialUi/theme';
import boxShadows from '../../common/boxShadows';

const styles = makeStyles((theme) => createStyles({
  merchBannerWrapper: {
    display: 'block',
    maxWidth: '1104px',
    margin: '20px auto',
    cursor: 'pointer',
    '&:focus': {
      padding: '5px'
    },
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      margin: '20px auto'
    }
  },
  merchBannerCard: {
    position: 'relative',
    paddingTop: getPaddingTopForAspectRatio(360, 100),
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      paddingTop: getPaddingTopForAspectRatio(1280, 90),
      boxShadow: boxShadows.cardShadow
    },
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.11)'
  },
  merchBanner: {
    display: 'block',
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0'
  },
  merchBannerContainer: {
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      padding: '0 16px'
    },
    [theme.breakpoints.up(largeStartBreakpoint)]: {
      padding: '0 24px'
    }
  }
}));

export default styles;
