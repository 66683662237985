import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { GetServerSideProps } from 'next';
import { useDecision } from '@optimizely/react-sdk';
import { onHomepageInitialLoadAnalytics } from '@/dataAnalytics/dataAnalyticsHelper';
import { usePageLoadedAndInView } from '@/dataAnalytics/hooks/useScrollTrackingForAnalytics';
import { ErrorPage } from '@/ErrorPage';
import SkipLinks from '../skipLinks';
import BreakingNewsBanner from '../BreakingNewsBanner/BreakingNewsBanner';
import HomepageHero from '../HomepageHero';
import CenteredContainer from '../../common/CenteredContainer';
import LocalizationRibbon from '../../localization/localizationRibbon/LocalizationRibbon';
import HomepageSideKick from '../sidekicks/HomepageSideKickSection';
import RedDoubleLineDivider from '../../common/RedDoubleLineDivider';
import HutFavoritesSectionLegacy from '@/homepage/HutFavoritesSectionLegacy';
import HutFavoritesSection from '@/homepage/HutFavoritesSection';
import MarketingBanners from '../marketingBanners';
import { GqlErrors, RootState } from '@/rootStateTypes';
import { cartSelectors } from '@/cart/cartSelectors';
import { openCartRail, resetOpenCartRailAfterRedirect } from '@/cart/actions';
import { AuthState } from '@/auth/userStates';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { getOrInitializeOptimizely } from '../../../optimizely/optimizely';
import { openLocalizationRail } from '@/localization/actions';
import initializePage from '@/common/pageSetup';
import { Paths } from '@/router/nextJsRouter';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { useHomepageAnalytics } from './hooks/useHomepageAnalytics';
import MerchandisingBanner from '../MerchandisingBanner';

export const CONTENT_TESTID = 'content';

interface HomePageProps {
  headerGqlError: GqlErrors['headerError'];
  heroGqlError: GqlErrors['heroError'];
  sidekickGqlError: GqlErrors['sidekickError'];
  localizationInitialized: LocalizationDetails['localizationInitialized'];
  loginStatusInitialized: boolean;
}

const HomePage = ({
  headerGqlError,
  heroGqlError,
  sidekickGqlError,
  localizationInitialized,
  loginStatusInitialized
}: HomePageProps) => {
  const dispatch = useDispatch();
  const { ref: pageRef, isPageInView } = usePageLoadedAndInView();
  const openCartRailAfterRedirect = useSelector(cartSelectors.openCartRailAfterRedirect);
  const allEndpointsFailed = headerGqlError && heroGqlError && sidekickGqlError;
  const analytics = useAnalytics();
  const optimizelyInstance = getOrInitializeOptimizely();
  const captureClick = () => optimizelyInstance?.track('HomePageClick');
  const isLocalized = useSelector(localizationSelectors.isLocalized);

  const [{ enabled: oneClickDecisionEnabled, variationKey }] = useDecision('cb-one_click');
  const [autoOpenLocalRailDecision] = useDecision('fr-web1825-localization_rail_auto_open');

  const [{enabled: showMerchBanner}] = useDecision('dtg-988-homepage-merchandizing-banner');

  const showNewHutFavoritesSection = oneClickDecisionEnabled && variationKey === 'control_pop_pizza_one_click_on';

  const HutFavoritesComponent = showNewHutFavoritesSection ? HutFavoritesSection : HutFavoritesSectionLegacy;

  useHomepageAnalytics();

  // Auto opens the rail when user is not localized
  useEffect(() => {
    if (autoOpenLocalRailDecision.enabled && !isLocalized) {
      dispatch(openLocalizationRail({ routeTo: Paths.DEALSW2 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openCartRailAfterRedirect) {
      dispatch(openCartRail());
      dispatch(resetOpenCartRailAfterRedirect());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPageInView && localizationInitialized && loginStatusInitialized) {
      analytics.push(() => onHomepageInitialLoadAnalytics(analytics.analyticsDataModel.page));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageInView]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://appds8093.blob.core.windows.net/94ba57b5-e5fc-4459-a91d-28bc381b6185/privacy-notices/e62e709a-134c-498b-bd56-c5a6455da2b9.json';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (allEndpointsFailed) {
    return <ErrorPage />;
  }

  return (
    <>
      <SkipLinks />
      <BreakingNewsBanner />
      <div id="content" data-testid={CONTENT_TESTID} ref={pageRef}>
        {
          showMerchBanner && <MerchandisingBanner/>
        }
        <HomepageHero />
        <CenteredContainer>
          <div role="presentation" onClick={captureClick}>
            <LocalizationRibbon />
            <HomepageSideKick />
            <RedDoubleLineDivider />
            <HutFavoritesComponent />
          </div>
          <RedDoubleLineDivider reverse />
          <MarketingBanners />
        </CenteredContainer>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  headerGqlError: state.presentational.gqlErrors.headerError,
  heroGqlError: state.presentational.gqlErrors.heroError,
  sidekickGqlError: state.presentational.gqlErrors.sidekickError,
  localizationInitialized: state.domain.localization.localizationInitialized,
  loginStatusInitialized: state.domain.user.loginStatus !== AuthState.UNKNOWN
});

export const getServerSideProps: GetServerSideProps = async (ctx) => initializePage(ctx, 'home');

export default connect(mapStateToProps, {})(HomePage);
