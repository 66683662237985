import React, { useEffect, useRef } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import HutFavoriteHeader from '@/homepage/HutFavoritesSection/components/HutFavoritesHeader';
import useGetHutFavorites from '@/homepage/HutFavoritesSection/hooks/useGetHutFavorites';
import { mobileStartBreakpoint } from '@/materialUi/theme';
import SingleHutFavoriteLegacy from './components/SingleHutFavoriteLegacy';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import SingleHutFavoriteLegacyLoading from './components/SingleHutFavoriteLegacyLoading';
import { homePopularPizzaImpressionLegacy } from '@/dataAnalytics/dataAnalyticsHelper';

export const HUT_FAVORITE_LOADING_PLACEHOLDER_COUNT = 4;
export const HUT_FAVORITE_LEGACY_CONTAINER_TESTID = 'hut-favorites-list-legacy-container';

const useStyles = makeStyles((theme: Theme) => createStyles({
  hutFavorites: {
    padding: '48px 0',
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      padding: '20px 0 32px'
    }
  },
  hutFavoritesContainer: {
    alignContent: 'flex-start',
    display: 'grid',
    gridGap: '24px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))'
  }
}));

const HutFavoritesSectionLegacy = (): JSX.Element => {
  const classes = useStyles();
  const analytics = useAnalytics();
  const hasFiredAnalyticsEvent = useRef(false);
  const { hutFavorites, isNationalStore, isLoading } = useGetHutFavorites();

  useEffect(() => {
    if (hasFiredAnalyticsEvent.current) return;
    hasFiredAnalyticsEvent.current = true;
    analytics.push(() => homePopularPizzaImpressionLegacy());
  }, [analytics]);

  return (
    <Grid data-testid={HUT_FAVORITE_LEGACY_CONTAINER_TESTID} className={classes.hutFavorites}>
      <HutFavoriteHeader />

      <Grid container className={classes.hutFavoritesContainer}>
        {isLoading && Array.from(Array(HUT_FAVORITE_LOADING_PLACEHOLDER_COUNT)).map((_, index) => (
          <SingleHutFavoriteLegacyLoading index={index} key={index} />
        ))}
        {!isLoading && hutFavorites.map((favorite, index) => (
          <SingleHutFavoriteLegacy
            key={index}
            favorite={favorite}
            isNationalStore={isNationalStore}
            index={index}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default HutFavoritesSectionLegacy;
