import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { GetServerSidePropsContext } from 'next';
import cacheApolloQueriesForPage from './cacheApolloQueriesForPage';
import fetchCookieData from './fetchCookieData';
import Time from '../time';
import ConversationIDStore from '../../telemetry/conversationIdStore';
import setConversationIdHeader from '../../telemetry/conversationIdStore/responseHeaders';
import pageRedirect from './pageRedirect';
import { AuthState } from '@/auth/userStates';
import { isGqlClientSideDecorationDisabled } from './apolloFeatureToggles';
import { getDatafile } from '@/../optimizely/datafile';
import { getOptimizelyUserId } from '@/../optimizely/user';

interface IndexProps {
  props: {
    auth: boolean;
    timestamp: string;
    conversationID: string;
    initialApolloState: NormalizedCacheObject;
    pageID: string;
  }
}

interface IndexRedirect {
  redirect: any
}

export interface UserAuthentication {
  authState: AuthState;
  expirationDate?: number; //unix timestamp?
}

export const isIndexProps = (response: IndexRedirect | IndexProps): response is IndexProps => (response as IndexProps).props !== undefined;

export const isIndexRedirect = (response: IndexRedirect | IndexProps): response is IndexRedirect => (response as IndexRedirect).redirect !== undefined;

const initializePage = async (
  ctx: GetServerSidePropsContext,
  pageID: string,
  isCategoryPage = false,
  authRequired = false
): Promise<IndexRedirect | IndexProps> => {
  const cookieData = await fetchCookieData(ctx);
  const { timezone, userExperience, userAuthentication } = cookieData;

  const redirect = pageRedirect({
    pageID,
    userExperience,
    authRequired,
    userAuthentication
  });

  if (redirect) {
    return redirect;
  }

  const optimizelyUserId = getOptimizelyUserId(ctx.req, ctx.res);
  const datafile = await getDatafile();
  const timestamp = Time.getCurrentTimestampForTimezone(timezone, new Date()).toString();
  const conversationId = ctx?.req?.headers?.conversationid || [''];
  const incomingConversationID = typeof conversationId === 'string' ? conversationId : conversationId[0];

  let initialApolloState: NormalizedCacheObject = {};
  if(pageID ==='homepage') {
    initialApolloState = await cacheApolloQueriesForPage(pageID, cookieData, timestamp, ctx);
  }

  ConversationIDStore.set(incomingConversationID);
  setConversationIdHeader(ctx.res);
  return {
    props: {
      optimizelyData: {
        datafile,
        optimizelyUserId,
      },
      auth: ctx?.query?.auth === '1',
      afterAuth: ctx?.query?.afterAuth || '',
      railStatus: ctx?.query?.railStatus || '',
      id: ctx?.query?.id ?? null,
      productCode: ctx?.query?.productCode ?? null,
      timestamp,
      conversationID: incomingConversationID,
      initialApolloState,
      ...cookieData,
      pageID,
      shouldDisableClientSideResolvers: isGqlClientSideDecorationDisabled(ctx),
    }
  };
};

export default initializePage;
