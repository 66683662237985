import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../colors';
import { mobileStartBreakpoint } from '../../materialUi/theme';

const useStyles = makeStyles((theme:Theme) => createStyles({
  redDoubleLineDividerReverse: {
    width: '100%',
    height: '9px',
    borderBottom: `2px solid ${colors.red}`,
    borderTop: `1px solid ${colors.red}`,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      position: 'absolute',
      left: 0
    }
  },
  redDoubleLineDivider: {
    width: '100%',
    height: '9px',
    borderTop: `2px solid ${colors.red}`,
    borderBottom: `1px solid ${colors.red}`,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      position: 'absolute',
      left: 0
    }
  }
}));

const RedDoubleLineDivider = ({ reverse }: any) => {
  const classes = useStyles();
  const dividerClass = reverse ? classes.redDoubleLineDividerReverse : classes.redDoubleLineDivider;

  return (
    <div className={dividerClass} />
  );
};

export default RedDoubleLineDivider;
