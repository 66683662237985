import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import colors from '../../../../common/colors';
import fontStyles from '../../../../common/fontStyles';
import { mobileStartBreakpoint } from '../../../../materialUi/theme';

export default makeStyles((theme:Theme) => createStyles({
  arrowIcon: {
    color: colors.gray900
  },
  title: {
    ...fontStyles.productTitle,
    [theme.breakpoints.down(mobileStartBreakpoint)]: {
      ...fontStyles.productTitleMobile
    }
  },
  subTitle: {
    fontSize: '14px',
    lineHeight: 1.57,
    color: colors.gray900,
    margin: 0
  },
  actionButton: {
    marginTop: '0px'
  },
  icon: {
    marginTop: '10px',
    marginRight: '-10px',
    fontWeight: 600,
    '&.MuiButton-root:hover': {
      backgroundColor: 'transparent',
      color: colors.blueLinkHover,
      textDecoration: 'underline'
    }
  },
  badgeText: {
    fontFamily: 'open_sans',
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: 2,
    letterSpacing: '1px',
    height: '20px',
    borderRadius: '3px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    flexWrap: 'wrap'
  }
}));
