import React from 'react';
import { Card, Grid, Skeleton } from '@mui/material';
import ResponsiveImageRender from '@/common/ResponsiveImageRender';
import useStyles from './styles';

export const HUT_FAVORITE_LEGACY_LOADING_TESTID = 'hut-favorite-legacy-loading';

const SingleHutFavoriteLegacyLoading = ({ index }: { index: number }): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid key={`loading-${index}`} item data-testid={HUT_FAVORITE_LEGACY_LOADING_TESTID}>
      <Card className={classes.hutFavorite}>
        <ResponsiveImageRender
          mobile="/images/placeholder-image.png"
          desktop="/images/placeholder-image.png"
          altText=""
          title=""
          pictureClassName={classes.hutPictureContainer}
          className={classes.hutFavoriteImage}
          testId=""
        />
        <div aria-hidden className={classes.hutFavoriteTextContent}>
          <Skeleton className={classes.hutFavoriteTitleLoading} />
          <Skeleton className={classes.hutFavoriteSubtitleLoading} />
        </div>
      </Card>
    </Grid>
  );
};

export default SingleHutFavoriteLegacyLoading;
