import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { getPaddingTopForAspectRatio } from '../../common/aspectRatio';
import { largeStartBreakpoint, mobileStartBreakpoint } from '../../materialUi/theme';
import boxShadows from '../../common/boxShadows';

const styles = makeStyles((theme) => createStyles({
  heroWrapper: {
    display: 'block',
    maxWidth: '1104px',
    margin: '20px auto',
    cursor: 'pointer',
    '&:focus': {
      padding: '5px'
    }
  },
  heroCard: {
    position: 'relative',
    paddingTop: getPaddingTopForAspectRatio(650, 360),
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      paddingTop: getPaddingTopForAspectRatio(1104, 360),
      boxShadow: boxShadows.cardShadow
    },
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.11)'
  },
  hero: {
    display: 'block',
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0'
  },
  heroContainer: {
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      padding: '0 16px'
    },
    [theme.breakpoints.up(largeStartBreakpoint)]: {
      padding: '0 24px'
    }
  }
}));

export default styles;
