import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { Product } from '@pizza-hut-us-development/client-core';
import { useGetPizzaMenu } from '@/clientCore/menu/hooks/useGetPizzaMenu';
import { useGetHutFavoritesQuery } from '@/api/reduxToolkit/hideProducts';

import ProductId from '@/common/ProductId';

import { localizationSelectors } from '@/localization/localizationSelectors';
import { NATIONAL_STORE_ID_FULL } from '@/localization/constants';

interface HutFavorites {
  hutFavorites: Product[];
  isNationalStore: boolean;
  isLoading: boolean;
}

const useGetHutFavorites = (): HutFavorites => {
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const [storeNumber, setStoreNumber] = useState<string | undefined>(undefined);

  useEffect(() => {
    setStoreNumber(storeDetails?.storeNumber ?? NATIONAL_STORE_ID_FULL);
  }, [storeDetails?.storeNumber]);

  const { signaturePizzas, isLoading } = useGetPizzaMenu();
  const { data: hutFavoritesProductCodes, isFetching } = useGetHutFavoritesQuery();

  const filterHutFavoritePizzas = useMemo(() => {
    const codes = hutFavoritesProductCodes?.productCodes?.yum;
    return signaturePizzas.filter((pizza) => {
      const { globalId } = new ProductId(pizza.id);
      return codes?.includes(globalId);
    });
  }, [
    hutFavoritesProductCodes?.productCodes?.yum,
    signaturePizzas
  ]);

  return {
    hutFavorites: filterHutFavoritePizzas,
    isNationalStore: storeNumber === NATIONAL_STORE_ID_FULL,
    isLoading: isLoading || isFetching
  };
};

export default useGetHutFavorites;
